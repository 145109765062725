import { doc, getDoc } from 'firebase/firestore';
import { snapshotToTyped, type User } from '@tn/shared';
import { defineStore } from 'pinia';
import * as console from 'node:console';

export const useUserStore = defineStore('user', () => {
  const db = useFirestore();
  const authUser = useCurrentUser();

  const userRef = computed(() => {
    if (!authUser.value) {
      return null;
    }

    return doc(db, 'users', authUser.value.uid);
  });

  const user = useDocument<User>(userRef);

  const getUserDoc = async (userId: string) => {
    try {
      const docRef = doc(db, 'users', userId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return snapshotToTyped<User>(docSnapshot);
      } else {
        console.log(`There is no document for ${userId}`);
        return null;
      }
    } catch (error) {
      console.error('Error occurred while retrieving user', error);
      return null;
    }
  };

  return { user, getUserDoc };
});
